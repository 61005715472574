import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 1007.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1007.000000) scale(0.100000,-0.100000)">

<path d="M9351 8318 c-23 -52 -71 -102 -125 -131 -35 -18 -57 -22 -141 -21
-153 2 -256 36 -513 170 -42 22 -55 17 -95 -34 -113 -149 -299 -283 -502 -361
-33 -13 -69 -27 -80 -31 -126 -50 -240 -71 -432 -77 l-163 -6 0 -197 0 -197
-147 -6 c-91 -4 -183 -15 -238 -27 -159 -37 -142 -41 -210 45 -33 42 -78 96
-100 119 -22 24 -51 58 -63 75 -42 54 -94 111 -103 111 -8 0 -94 -66 -204
-155 -20 -16 -54 -43 -75 -60 -88 -68 -157 -123 -176 -138 -10 -9 -48 -39 -84
-67 -36 -28 -72 -57 -80 -63 -8 -7 -33 -26 -55 -42 -22 -17 -46 -36 -54 -43
-22 -21 -61 -45 -66 -40 -5 5 11 171 25 248 5 30 12 71 15 90 7 39 23 105 35
135 4 11 13 38 19 60 25 85 157 366 211 452 32 51 32 53 -10 83 -29 21 -30 21
-46 1 -35 -40 -164 -290 -204 -396 -24 -61 -49 -135 -60 -175 -7 -25 -16 -56
-21 -70 -13 -35 -49 -203 -61 -280 -6 -37 -11 -283 -12 -565 -2 -495 -4 -527
-37 -645 -31 -108 -132 -273 -195 -318 -14 -9 -44 -32 -67 -49 -23 -18 -48
-33 -54 -33 -7 0 -13 -4 -13 -9 0 -8 -276 -149 -335 -171 -37 -15 -100 -43
-191 -86 -131 -63 -143 -66 -137 -46 2 10 18 27 36 39 18 12 48 37 67 55 20
18 63 55 95 83 33 27 62 52 65 55 3 3 25 21 50 40 25 19 52 42 60 51 8 9 42
37 75 64 163 129 281 289 318 430 6 23 13 247 18 540 4 275 10 520 13 545 9
68 31 176 57 288 13 53 75 247 89 277 5 11 24 54 42 95 36 81 112 215 128 225
20 12 9 32 -29 53 l-39 22 -53 -22 c-30 -12 -58 -25 -64 -30 -5 -4 -17 -8 -27
-8 -10 0 -26 -4 -36 -9 -40 -21 -132 -32 -232 -28 -116 5 -146 11 -310 58
-121 36 -285 48 -359 28 -215 -58 -409 -242 -491 -464 -18 -50 -30 -93 -30
-111 -1 -44 -8 -45 -166 -23 -177 24 -248 35 -329 48 -180 29 -422 64 -570 82
-44 5 -140 12 -213 16 -496 23 -919 -206 -1101 -598 -42 -90 -89 -232 -97
-288 -6 -46 -5 -48 25 -61 19 -7 35 -9 40 -4 4 5 20 43 36 84 78 204 177 322
341 404 130 65 249 96 374 96 121 0 280 -12 280 -21 0 -4 -35 -33 -77 -66
-193 -144 -296 -241 -417 -392 -60 -74 -156 -213 -156 -226 0 -2 -13 -26 -28
-52 -16 -26 -45 -89 -65 -138 -72 -178 -97 -314 -97 -525 0 -238 31 -372 130
-570 168 -336 471 -567 884 -674 317 -83 696 -77 979 15 108 35 245 100 337
160 79 51 217 164 230 189 5 10 27 24 47 30 21 6 47 15 58 20 11 4 40 13 65
20 25 7 65 18 90 25 41 12 113 25 225 40 111 16 296 16 415 1 144 -19 203 -30
245 -44 17 -6 50 -16 75 -23 25 -6 54 -15 65 -19 11 -5 29 -11 40 -13 40 -10
171 -61 203 -79 l34 -19 39 36 c21 19 41 35 44 35 3 0 2 -27 -1 -59 -6 -54 -5
-60 15 -71 37 -20 64 -9 84 35 23 49 44 73 80 92 61 31 143 17 410 -72 144
-47 166 -51 213 -36 16 5 70 22 119 36 50 15 149 45 220 67 72 22 159 49 195
60 189 56 245 73 355 109 66 22 129 43 140 48 11 5 32 13 48 20 15 6 39 16 55
22 15 7 36 15 47 20 296 117 481 278 540 471 29 95 34 215 35 750 1 419 3 528
14 537 8 7 106 12 276 13 l264 3 53 124 c29 68 53 130 53 137 0 12 -54 14
-324 14 -286 0 -325 2 -330 16 -8 20 -8 448 0 468 8 21 16 20 110 -8 178 -53
352 -62 478 -25 227 68 347 204 370 422 l6 57 -45 0 c-44 0 -46 -1 -64 -42z
m-4011 -314 c0 -4 -10 -16 -22 -28 -39 -37 -200 -215 -217 -238 -18 -27 -101
-131 -110 -138 -3 -3 -12 -17 -20 -32 -8 -16 -18 -28 -23 -28 -4 0 -8 -7 -8
-15 0 -8 -8 -29 -18 -47 -39 -71 -62 -191 -62 -319 0 -121 -5 -125 -98 -81
-100 47 -151 62 -217 62 -143 0 -288 -86 -346 -207 -11 -23 -25 -45 -30 -49
-14 -8 -3 -21 32 -38 30 -15 33 -14 63 6 17 12 37 28 43 34 7 7 36 20 64 29
49 17 76 17 180 1 19 -3 82 -28 89 -35 3 -4 17 -12 32 -20 73 -38 161 -160
197 -272 13 -43 15 -245 1 -254 -5 -3 -42 11 -82 31 -90 46 -99 49 -171 62
-38 7 -67 8 -83 2 -14 -6 -36 -10 -49 -10 -64 0 -187 -103 -226 -189 -25 -57
-25 -59 16 -78 35 -17 35 -17 68 14 47 45 69 53 138 53 98 0 176 -52 216 -144
21 -48 16 -185 -9 -247 -29 -74 -49 -94 -77 -80 -27 15 -33 11 -67 -47 -55
-95 -159 -229 -233 -302 -115 -115 -258 -203 -441 -273 -122 -47 -150 -53
-150 -36 0 8 0 464 0 1014 -1 550 -1 1023 0 1052 l0 52 163 -6 c213 -8 303 8
453 79 77 36 169 119 212 190 37 62 88 199 97 263 6 38 4 44 -20 64 -38 29
-47 27 -82 -21 -47 -66 -123 -136 -173 -161 -38 -18 -66 -22 -187 -25 -164 -5
-166 -4 -108 72 19 26 35 50 35 54 0 26 124 118 191 142 62 23 233 30 289 13
42 -14 168 -33 213 -33 86 0 266 50 359 101 10 5 23 9 30 9 7 0 24 9 38 20 14
11 33 20 43 20 9 0 17 5 17 10 0 6 11 10 25 10 14 0 25 -3 25 -6z m2720 -1043
c0 -1057 -2 -1077 -97 -1250 -64 -116 -240 -268 -385 -334 -21 -9 -53 -24 -70
-32 -32 -14 -117 -45 -124 -45 -18 0 11 36 75 95 87 80 136 140 181 218 83
148 89 237 90 1302 1 897 -2 855 58 855 28 0 232 66 246 80 5 5 13 7 18 4 4
-3 8 -405 8 -893z m-1859 431 c13 -15 36 -43 50 -62 15 -19 41 -51 58 -70 18
-19 53 -61 78 -93 l46 -59 -47 -96 c-25 -54 -46 -101 -46 -107 0 -5 -40 -38
-88 -72 -48 -35 -107 -78 -131 -95 -24 -18 -58 -41 -75 -52 -17 -10 -33 -21
-36 -25 -3 -4 -36 -29 -75 -56 -38 -26 -80 -55 -91 -64 -12 -9 -32 -23 -44
-31 -13 -8 -43 -30 -67 -48 -35 -26 -83 -52 -96 -52 -1 0 -2 130 -2 288 l0
288 25 30 c14 16 41 40 60 54 19 13 53 39 75 57 50 41 120 97 182 145 26 21
80 63 118 93 39 30 73 55 76 55 3 0 17 -12 30 -28z m-3377 -38 c11 -28 7
-1673 -4 -1769 -14 -122 -40 -174 -131 -262 -41 -40 -79 -73 -86 -73 -7 0 -20
8 -30 18 -10 10 -43 39 -73 64 -108 91 -231 263 -293 412 -136 325 -118 711
48 1063 78 165 224 357 378 498 70 64 72 65 128 65 43 0 58 -4 63 -16z m561
-85 c28 -4 86 -13 129 -19 44 -6 88 -15 98 -21 17 -9 18 -52 18 -1089 0 -1059
0 -1079 -19 -1090 -29 -15 -254 -31 -346 -25 -132 9 -245 32 -355 70 -92 32
-210 86 -210 96 0 5 78 74 174 153 205 172 333 303 361 372 19 47 20 76 23
799 1 413 5 756 7 764 4 10 14 11 38 6 17 -4 55 -11 82 -16z m3913 -885 l2
-760 -89 -87 c-81 -79 -93 -87 -128 -87 -21 0 -48 7 -60 15 -23 16 -153 205
-153 222 0 5 -9 18 -20 28 -11 10 -20 22 -20 27 0 4 -29 56 -65 114 -36 59
-65 108 -65 111 0 2 -4 9 -10 16 -5 7 -36 51 -67 98 -92 138 -141 198 -238
295 -87 85 -172 151 -243 188 -18 9 -32 23 -32 30 0 8 38 41 84 73 114 78 269
187 396 278 194 138 245 172 270 180 37 12 212 23 330 21 l105 -1 3 -761z
m-1550 -101 c23 -3 45 -10 48 -14 3 -5 14 -9 25 -9 22 0 142 -58 147 -71 2 -5
10 -9 17 -9 17 0 186 -164 211 -205 19 -30 72 -104 100 -138 8 -9 14 -20 14
-24 0 -3 6 -14 14 -22 31 -36 27 -54 -22 -106 -26 -27 -68 -75 -94 -105 -113
-134 -141 -159 -170 -153 -16 3 -28 9 -28 14 0 5 -7 9 -15 9 -8 0 -23 4 -33 9
-9 5 -44 19 -77 31 -33 12 -69 26 -80 30 -69 29 -314 69 -458 75 -38 2 -72 6
-75 10 -4 4 7 18 23 32 157 130 217 202 275 333 37 83 48 132 57 235 6 81 16
99 52 90 14 -3 45 -8 69 -12z m700 -697 c17 -32 32 -64 32 -72 0 -8 4 -14 8
-14 5 0 18 -18 30 -40 13 -26 17 -40 9 -40 -7 0 -52 5 -100 10 -60 7 -108 7
-154 0 -36 -6 -67 -9 -69 -7 -8 8 189 226 201 223 6 -2 25 -29 43 -60z m832
-175 c0 -75 -94 -171 -167 -171 -21 0 -33 4 -31 11 5 14 181 189 191 189 4 0
7 -13 7 -29z"/>
<path d="M895 5315 c-202 -152 -303 -234 -308 -251 -7 -20 -1 -38 34 -97 24
-40 49 -85 57 -100 23 -46 53 -54 113 -28 28 11 57 21 62 21 6 0 -12 -24 -41
-53 -31 -32 -52 -61 -52 -75 0 -12 4 -22 8 -22 4 0 17 -20 29 -43 12 -24 37
-69 57 -100 41 -65 58 -69 129 -38 23 10 64 28 91 40 26 12 51 20 54 17 3 -4
-15 -26 -41 -51 -25 -24 -54 -61 -63 -82 -42 -101 31 -264 146 -323 86 -44
187 -22 275 61 17 15 41 32 55 38 43 17 139 99 167 144 37 56 39 150 5 215
-30 57 -83 114 -126 137 -20 11 -36 23 -36 28 0 4 21 17 48 28 53 23 82 46 82
65 0 7 -17 41 -37 76 -21 35 -43 72 -48 83 -12 22 -57 25 -112 7 -26 -8 -31
-8 -26 4 3 8 10 14 15 14 16 0 58 49 58 67 0 26 -94 181 -115 191 -15 7 -32 5
-54 -4 -50 -21 -69 -18 -40 6 71 58 71 61 6 172 -33 56 -49 74 -67 76 -19 2
-97 -51 -325 -223z m350 101 c16 -30 27 -57 25 -59 -3 -2 -92 -63 -197 -136
-106 -73 -195 -136 -198 -140 -3 -5 3 -19 13 -31 l18 -22 69 35 c74 38 202 97
210 97 2 0 34 14 70 30 36 17 74 30 84 30 16 0 81 -87 81 -110 0 -7 -26 -30
-75 -68 -5 -4 -75 -52 -155 -106 -80 -54 -153 -106 -163 -115 -19 -18 -15 -46
8 -55 8 -3 81 26 162 65 214 101 280 129 302 129 12 0 61 -76 61 -94 0 -12
-135 -86 -156 -86 -2 0 -70 -29 -151 -65 -82 -36 -157 -70 -168 -74 -11 -5
-45 -21 -76 -35 -31 -14 -65 -26 -75 -26 -13 1 -32 24 -61 75 -24 41 -43 78
-43 83 0 4 57 53 126 107 70 55 133 106 140 113 18 17 18 36 0 52 -12 9 -47
-2 -180 -55 -90 -37 -169 -64 -175 -61 -16 10 -93 144 -89 155 3 10 20 23 327
253 123 92 227 168 230 168 4 0 20 -24 36 -54z m280 -750 c64 -38 115 -122
115 -189 0 -35 -30 -91 -63 -118 -12 -9 -35 -29 -52 -43 -16 -14 -33 -26 -37
-26 -4 0 -34 38 -68 85 -77 107 -88 109 -187 38 -87 -62 -102 -94 -62 -134 25
-25 64 -21 109 11 15 11 35 20 44 20 16 0 66 -59 66 -78 0 -22 -85 -62 -133
-62 -81 0 -165 87 -182 188 -13 79 25 124 210 250 135 91 168 100 240 58z"/>
<path d="M1443 4559 c-50 -35 -60 -59 -37 -94 23 -35 61 -32 112 7 33 26 42
39 42 63 0 62 -49 72 -117 24z"/>
<path d="M9616 5255 c-9 -13 -16 -27 -16 -30 0 -3 -11 -23 -25 -43 -33 -48
-32 -74 6 -111 32 -32 28 -38 -16 -21 -38 15 -73 -6 -101 -59 -31 -59 -41 -64
-83 -35 -36 24 -67 30 -86 16 -16 -11 -95 -129 -95 -141 0 -5 -13 -23 -29 -38
-42 -41 -121 -159 -121 -181 0 -21 70 -82 94 -82 32 0 24 -19 -18 -44 -51 -30
-71 -58 -87 -119 -10 -40 -15 -46 -43 -51 -41 -8 -105 -70 -113 -109 -3 -17
-15 -32 -27 -37 -12 -4 -34 -23 -48 -42 -14 -18 -37 -44 -49 -57 -13 -14 -31
-38 -39 -55 -9 -17 -28 -34 -47 -40 -78 -27 -163 -110 -188 -183 -15 -42 -55
-61 -55 -25 0 10 -14 38 -30 60 -42 58 -63 55 -144 -19 -84 -78 -99 -117 -67
-181 11 -24 21 -57 21 -72 0 -39 45 -94 83 -102 26 -5 52 -31 139 -143 167
-211 189 -235 218 -228 34 8 160 114 160 133 0 8 -7 28 -15 43 l-14 29 62 -5
c53 -4 67 -2 97 19 19 13 45 39 57 57 13 19 39 44 57 55 69 42 136 108 136
132 0 17 7 25 28 30 15 4 41 23 58 43 18 20 44 50 58 66 14 17 26 38 26 48 0
10 11 21 23 26 35 13 137 130 137 157 0 15 8 24 24 28 13 3 49 35 80 70 43 48
56 71 56 95 0 24 6 34 24 41 13 5 40 33 60 63 20 31 43 61 51 67 8 7 15 26 15
42 0 23 6 31 28 39 15 5 38 28 52 51 14 23 35 56 48 75 41 62 36 70 -114 168
-75 49 -132 91 -126 93 9 3 117 -31 210 -65 37 -14 42 -19 42 -47 0 -41 7 -50
55 -75 61 -31 76 -22 135 85 44 80 52 103 52 148 1 76 -25 108 -178 223 -387
292 -384 290 -418 238z m276 -207 c107 -81 210 -161 227 -178 44 -45 45 -84 0
-171 -48 -97 -79 -112 -79 -39 0 50 -11 57 -155 104 -296 97 -390 132 -393
146 -1 8 10 30 25 48 31 37 23 37 118 -4 17 -7 48 -19 70 -27 22 -8 76 -29
120 -47 74 -29 80 -30 92 -14 7 10 13 23 13 29 0 6 -37 36 -82 66 -46 29 -88
59 -94 65 -7 6 -39 28 -73 50 -33 21 -61 45 -61 52 0 14 29 63 44 74 13 9 0
17 228 -154z m-443 -209 c156 -100 423 -275 445 -291 19 -14 18 -16 -9 -62
-15 -26 -34 -50 -41 -53 -7 -2 -57 24 -111 59 -211 138 -282 184 -375 245 -54
35 -98 68 -98 74 0 15 59 99 70 99 5 0 59 -32 119 -71z m-244 -157 c-8 -15
-25 -40 -38 -55 -20 -24 -26 -26 -35 -14 -10 11 -5 24 23 60 23 30 41 44 50
41 13 -5 13 -9 0 -32z m170 -83 c22 -17 117 -84 210 -151 94 -66 172 -122 174
-124 7 -7 -61 -104 -73 -104 -7 0 -52 28 -101 63 -49 34 -120 85 -160 112
-167 117 -184 131 -185 146 0 8 14 34 31 58 35 47 40 47 104 0z m-112 -188
c23 -17 60 -47 83 -68 22 -21 70 -63 105 -93 106 -91 159 -142 159 -153 0 -13
-60 -77 -73 -77 -11 0 -36 20 -128 101 -217 195 -245 215 -284 205 -30 -8 -38
5 -24 41 29 78 90 94 162 44z m-168 -160 c6 -19 71 -80 255 -242 52 -45 95
-88 95 -94 0 -14 -61 -85 -73 -85 -5 0 -40 28 -78 63 -260 233 -295 260 -330
251 -21 -5 -24 -3 -24 24 0 43 37 83 86 92 60 12 62 11 69 -9z m-169 -177 c4
-16 71 -82 178 -176 94 -83 172 -159 174 -167 1 -9 -14 -33 -34 -54 l-37 -38
-31 28 c-17 15 -33 30 -36 33 -3 3 -43 39 -90 80 -47 41 -98 87 -115 102 -16
16 -53 47 -82 70 -29 24 -52 50 -53 58 0 9 13 32 29 51 38 46 87 52 97 13z
m-99 -186 c41 -39 194 -215 266 -307 l28 -36 -37 -32 c-21 -17 -51 -33 -66
-35 -25 -4 -31 0 -47 36 -10 23 -40 65 -66 94 -55 61 -89 69 -106 25 -17 -46
-2 -90 51 -145 42 -43 53 -50 79 -46 25 4 31 1 31 -14 0 -47 -51 -88 -108 -88
-21 0 -40 12 -76 50 -57 60 -77 97 -70 127 4 12 24 57 45 99 21 42 39 85 39
97 0 52 -90 135 -132 122 -45 -15 -50 -53 -13 -107 33 -49 32 -61 -11 -93 -33
-25 -36 -26 -54 -10 -89 80 -44 215 95 285 63 32 98 27 152 -22z m-473 -107
c3 -4 -1 -17 -9 -27 -19 -25 -19 -68 0 -84 10 -8 30 -10 60 -6 l45 7 0 -38 c0
-24 8 -48 20 -63 187 -234 253 -318 261 -332 7 -13 0 -24 -36 -53 -24 -20 -49
-34 -54 -31 -6 4 -63 73 -127 154 -141 178 -173 212 -195 212 -25 0 -48 27
-42 49 3 11 -7 39 -22 65 -30 52 -26 69 33 124 34 32 56 40 66 23z m289 -274
l-6 -38 -23 22 c-29 27 -31 49 -4 49 11 0 20 5 20 12 0 8 3 9 10 2 5 -5 7 -26
3 -47z"/>
<path d="M2671 4673 c-14 -3 -35 -12 -48 -20 -20 -13 -51 -15 -192 -10 l-169
5 -6 -31 c-4 -18 -9 -44 -13 -59 -8 -39 11 -36 49 8 26 30 36 35 67 32 35 -3
36 -4 33 -43 -1 -22 -9 -60 -16 -85 -7 -25 -18 -67 -24 -95 -6 -27 -13 -63
-17 -78 -4 -20 -15 -31 -35 -39 -17 -5 -30 -16 -30 -24 0 -11 22 -14 104 -14
58 0 107 4 111 9 3 5 -8 19 -25 31 -21 15 -29 28 -26 43 6 32 34 163 49 232
l14 60 53 3 c29 2 57 -1 62 -6 5 -5 5 -27 -1 -53 -25 -114 -33 -150 -35 -171
-2 -13 -9 -49 -15 -82 -14 -64 -6 -85 25 -68 11 6 26 13 35 15 9 3 20 27 28
63 17 78 31 104 53 104 11 0 18 7 18 20 0 12 7 20 16 20 14 0 15 -8 9 -47 -4
-27 -11 -56 -16 -65 -16 -29 -10 -84 10 -102 19 -17 22 -17 65 13 56 39 64 51
37 58 -25 6 -26 20 -6 96 20 78 19 100 -6 115 -26 16 -70 1 -107 -35 -31 -31
-46 -30 -38 5 8 32 22 100 32 160 7 45 7 45 -45 35z"/>
<path d="M4038 4657 c-43 -12 -56 -33 -27 -42 26 -9 25 -20 -19 -235 -5 -25
-15 -69 -22 -98 -7 -30 -8 -58 -4 -62 5 -5 26 -3 47 3 27 9 37 17 37 33 0 29
20 84 31 84 5 0 9 -5 9 -12 0 -24 43 -99 62 -109 26 -14 61 3 90 43 l19 28
-30 0 c-27 0 -34 6 -56 52 -32 67 -32 94 -1 102 14 3 31 3 39 0 16 -6 47 20
47 40 0 21 -27 38 -49 31 -12 -3 -40 -24 -63 -45 -62 -56 -67 -48 -38 70 6 25
13 64 17 88 5 41 5 42 -23 41 -16 -1 -46 -6 -66 -12z"/>
<path d="M7225 4648 c-48 -38 -66 -58 -77 -88 -5 -16 -25 -41 -44 -55 -35 -27
-37 -45 -4 -45 20 0 20 -16 0 -125 -5 -27 -11 -63 -14 -80 -17 -120 -30 -142
-71 -123 -49 22 -75 -20 -35 -57 17 -15 24 -16 49 -5 66 27 128 120 150 225
30 144 36 158 65 166 59 16 70 49 15 49 -26 0 -29 3 -29 34 0 42 16 86 31 86
6 0 19 -10 29 -22 15 -19 20 -21 34 -9 10 8 16 25 14 40 -3 23 -8 26 -44 29
-30 2 -48 -3 -69 -20z"/>
<path d="M4693 4637 c-29 -13 -37 -40 -21 -66 15 -25 41 -27 62 -5 35 34 2 91
-41 71z"/>
<path d="M3320 4498 c-49 -24 -49 -23 -85 -86 -18 -30 -25 -57 -25 -93 0 -44
4 -55 31 -80 37 -34 55 -36 112 -9 62 30 91 55 84 73 -5 13 -11 13 -47 -5 -33
-17 -46 -19 -66 -10 -13 7 -24 18 -24 26 0 18 38 46 62 46 10 0 18 5 18 10 0
6 5 10 12 10 21 0 68 53 68 76 0 27 -44 64 -74 64 -11 -1 -41 -11 -66 -22z
m60 -46 c-1 -23 -35 -62 -54 -62 -24 0 -19 34 9 64 26 28 45 27 45 -2z"/>
<path d="M4660 4498 c-56 -33 -80 -78 -41 -78 21 0 21 6 -8 -91 -28 -93 0
-135 66 -98 57 32 88 84 43 72 -26 -7 -26 18 -1 108 18 67 14 110 -11 108 -7
0 -29 -10 -48 -21z"/>
<path d="M5175 4511 c-6 -5 -25 -18 -44 -28 -43 -24 -49 -39 -18 -47 23 -5 24
-8 16 -48 -24 -117 -31 -159 -26 -164 9 -9 78 8 82 20 1 6 8 36 15 67 9 39 23
68 47 94 48 53 58 37 39 -58 -20 -93 -20 -111 -2 -126 10 -8 19 -8 37 2 54 28
79 50 79 68 0 22 -10 24 -29 7 -15 -15 -13 0 12 112 13 57 13 68 1 88 -8 12
-20 22 -28 22 -17 0 -106 -59 -106 -71 0 -5 -4 -9 -10 -9 -5 0 -10 13 -10 28
0 42 -31 66 -55 43z"/>
<path d="M5855 4507 c-16 -7 -44 -28 -62 -47 -40 -42 -46 -105 -13 -135 14
-13 17 -22 10 -29 -5 -5 -10 -24 -10 -41 0 -26 -7 -36 -37 -56 -29 -18 -39
-32 -41 -56 -3 -28 2 -36 30 -53 18 -11 48 -23 65 -26 74 -14 193 51 193 105
0 28 -45 81 -68 81 -7 0 -12 4 -12 9 0 5 -10 12 -22 15 l-23 6 24 20 c13 11
28 20 35 20 22 1 65 48 74 83 6 20 8 38 5 40 -2 3 10 15 26 27 17 12 31 25 31
29 0 4 -24 8 -52 8 -29 1 -69 3 -88 6 -19 3 -48 0 -65 -6z m55 -82 c0 -40 -5
-61 -18 -76 -18 -19 -19 -19 -36 0 -15 17 -16 24 -5 63 15 50 26 68 45 68 10
0 14 -15 14 -55z m-40 -241 c22 -15 40 -32 40 -38 0 -39 -72 -49 -108 -16 -19
18 -20 24 -11 43 10 19 28 36 37 37 1 0 20 -12 42 -26z"/>
<path d="M6434 4491 c-81 -60 -81 -96 2 -161 24 -19 43 -39 41 -45 -6 -19 -43
-16 -62 5 -22 24 -51 26 -72 4 -21 -20 -9 -41 36 -68 29 -17 37 -17 72 -6 41
14 99 69 99 95 0 28 -22 61 -61 90 -39 29 -48 59 -19 61 8 0 23 -9 32 -20 14
-17 21 -19 35 -10 30 18 28 58 -2 72 -39 18 -57 15 -101 -17z"/>
<path d="M7712 4506 c-41 -16 -110 -82 -128 -123 -22 -52 -18 -128 8 -153 12
-11 24 -20 27 -20 7 0 48 27 91 58 l31 24 -6 -24 c-3 -13 0 -31 9 -43 14 -19
14 -19 51 0 40 21 83 70 72 81 -4 4 -13 2 -21 -5 -23 -19 -24 4 -2 100 14 61
17 92 10 99 -16 16 -105 20 -142 6z m48 -77 c0 -59 -50 -138 -89 -139 -14 0
-12 42 7 113 13 49 29 67 58 67 21 0 24 -4 24 -41z"/>
<path d="M8267 4490 c-48 -30 -62 -60 -29 -60 17 0 18 -5 11 -42 -25 -132 -29
-161 -25 -165 3 -3 22 -1 43 4 30 7 39 14 41 33 6 57 27 107 61 143 59 62 62
50 26 -106 -10 -42 -16 -79 -13 -82 6 -6 68 14 86 28 6 5 12 25 12 43 0 19 4
42 9 52 5 9 12 24 15 33 5 20 74 74 82 66 3 -3 1 -20 -4 -39 -6 -18 -16 -53
-22 -77 -6 -23 -14 -49 -16 -55 -3 -7 4 -22 15 -35 18 -21 21 -21 48 -7 15 9
41 26 57 40 l28 23 -22 16 c-18 13 -21 22 -16 49 19 97 19 133 -2 151 -18 17
-23 17 -49 3 -16 -8 -35 -22 -42 -30 -19 -23 -51 -20 -51 4 0 18 -26 40 -48
40 -5 0 -29 -18 -54 -40 -47 -41 -58 -42 -58 -5 0 20 -17 45 -30 45 -3 0 -27
-13 -53 -30z"/>
<path d="M1729 4282 c-82 -52 -135 -143 -123 -208 7 -41 -11 -53 -37 -25 -11
12 -30 21 -43 21 -28 0 -91 -66 -113 -118 -32 -76 7 -176 101 -257 57 -51 104
-67 174 -63 38 2 46 0 43 -12 -12 -42 -12 -83 -1 -110 14 -34 132 -150 152
-150 8 0 21 -16 28 -36 16 -41 84 -104 111 -104 10 0 35 -16 56 -36 55 -53
134 -104 160 -104 28 0 28 -8 3 -40 -32 -41 -25 -64 33 -106 130 -97 182 -117
249 -95 46 15 85 54 336 328 137 151 147 165 133 192 -12 22 -132 111 -149
111 -6 0 -25 -12 -42 -26 -37 -31 -47 -27 -27 13 21 41 8 63 -68 118 -54 38
-72 46 -96 41 -26 -5 -31 -2 -43 29 -28 67 -114 138 -213 175 -39 15 -44 48
-14 87 11 13 17 32 14 41 -9 29 -136 142 -159 142 -11 0 -29 -9 -39 -20 -23
-25 -74 -27 -81 -2 -28 90 -106 187 -179 221 -62 29 -112 27 -166 -7z m148
-53 c71 -40 133 -125 133 -181 0 -29 -36 -88 -53 -88 -7 0 -26 14 -42 30 -25
26 -27 33 -17 48 37 52 35 93 -5 111 -32 15 -62 5 -91 -28 -34 -39 -36 -65
-13 -149 39 -140 34 -172 -40 -236 -73 -62 -137 -56 -211 19 -40 40 -78 109
-78 139 0 26 40 94 59 101 10 4 27 -6 49 -30 l33 -36 -30 -35 c-17 -19 -31
-41 -31 -50 0 -18 44 -64 62 -64 25 0 77 39 93 70 15 29 15 37 -4 115 -12 46
-21 105 -21 131 0 45 3 51 55 101 62 60 91 66 152 32z m365 -246 c44 -43 46
-53 17 -79 -14 -12 -19 -27 -16 -43 8 -39 -2 -51 -42 -51 -27 0 -43 -7 -59
-25 -12 -14 -71 -77 -132 -140 -106 -111 -110 -116 -110 -159 0 -25 -4 -48
-10 -51 -14 -9 -98 79 -106 110 -4 14 -3 35 2 45 5 10 72 84 149 165 116 122
141 154 145 184 4 31 9 36 35 39 16 2 40 12 53 23 14 10 27 19 30 19 4 0 23
-17 44 -37z m-304 -93 c22 0 12 -36 -18 -65 -16 -16 -32 -27 -34 -24 -2 2 -7
36 -11 74 l-7 70 29 -27 c16 -16 34 -28 41 -28z m468 -161 c50 -30 102 -94
109 -132 5 -23 1 -46 -9 -69 -31 -63 -267 -378 -284 -378 -5 0 -27 17 -50 38
-48 44 -54 77 -18 93 13 6 47 42 76 80 48 63 52 72 42 94 -9 19 -19 25 -45 25
-72 0 -155 -97 -142 -166 6 -33 5 -34 -29 -34 -26 0 -41 8 -65 35 -17 20 -31
45 -31 56 0 24 57 113 94 148 30 28 68 30 151 6 84 -25 132 -17 167 26 36 45
47 99 23 122 -27 27 -55 20 -96 -24 -43 -46 -59 -46 -104 1 l-29 30 29 30 c16
17 36 30 45 30 9 0 25 6 35 14 22 16 83 5 131 -25z m-289 -104 c7 -21 2 -25
-25 -18 -18 4 -20 8 -11 19 15 18 28 18 36 -1z m571 -113 l34 -28 -22 -39
c-65 -116 -151 -297 -148 -312 5 -26 47 -29 63 -5 49 68 219 262 232 262 20 0
73 -38 73 -54 0 -21 -377 -422 -409 -436 -42 -17 -74 -7 -153 47 -58 39 -66
48 -57 64 8 16 14 17 31 8 40 -21 61 2 118 127 91 203 115 257 146 326 17 40
35 67 44 67 8 0 30 -12 48 -27z m-248 -239 c-87 -195 -79 -183 -121 -183 -44
0 -50 14 -21 46 10 10 29 35 42 55 42 61 115 147 118 138 2 -4 -6 -29 -18 -56z"/>
<path d="M7967 3445 c-21 -8 -61 -31 -88 -51 -103 -75 -130 -179 -75 -284 36
-68 52 -82 92 -75 l31 5 -10 -37 c-8 -33 -6 -46 16 -94 29 -62 87 -137 121
-156 55 -31 163 -1 201 57 11 16 25 30 32 30 15 0 61 25 126 69 34 24 47 39
47 57 0 13 -51 103 -119 208 -135 210 -164 248 -206 270 -37 19 -117 19 -168
1z m150 -60 c12 -9 40 -41 62 -73 67 -95 211 -326 211 -337 0 -5 -19 -22 -43
-37 -34 -22 -49 -26 -73 -19 -19 5 -29 14 -30 27 -1 24 -15 51 -62 122 -28 42
-40 52 -62 52 -16 0 -30 -7 -34 -16 -30 -79 46 -224 110 -208 18 5 24 2 24
-11 0 -68 -111 -113 -158 -62 -27 29 -92 137 -92 153 0 7 25 50 57 96 65 96
71 131 33 190 -33 49 -62 65 -98 52 -35 -12 -41 -53 -13 -100 28 -47 27 -55
-14 -81 -50 -33 -63 -29 -87 24 -17 39 -19 51 -9 84 33 111 201 198 278 144z"/>
<path d="M3104 3261 c-83 -14 -120 -56 -212 -244 -102 -208 -104 -269 -13
-357 60 -59 112 -83 188 -88 53 -4 69 0 109 22 25 14 48 26 50 26 2 0 4 -13 4
-29 0 -106 127 -209 270 -218 55 -4 72 -1 115 21 27 14 51 24 52 22 2 -2 -2
-25 -9 -51 -12 -43 -11 -48 7 -61 29 -21 146 -54 194 -54 22 0 41 -4 41 -9 0
-18 169 -66 204 -57 13 3 29 -2 42 -15 26 -25 155 -53 203 -45 22 3 41 0 51
-8 8 -7 44 -19 80 -25 36 -7 75 -14 87 -17 28 -5 62 26 70 66 5 27 23 43 23
21 0 -18 53 -72 94 -96 57 -33 153 -48 219 -32 39 9 64 23 101 59 28 27 53 48
57 48 4 0 12 -13 17 -29 36 -101 227 -155 342 -96 34 18 100 81 100 96 0 5 5
9 10 9 6 0 10 -7 10 -16 0 -9 16 -32 36 -50 l35 -34 103 0 c87 0 105 3 119 18
10 10 17 24 17 30 0 19 21 24 38 9 43 -36 158 -48 249 -25 45 11 113 66 139
113 11 19 12 19 47 -12 58 -51 147 -56 208 -12 16 11 44 19 67 19 21 0 52 4
68 9 16 5 48 14 71 20 53 14 59 32 38 118 -21 89 -48 204 -86 373 -6 25 -14
61 -19 80 -5 19 -14 58 -20 85 -31 139 -34 145 -83 145 -47 0 -161 -32 -175
-49 -9 -11 -11 -33 -6 -73 l7 -58 -29 0 c-70 0 -127 -31 -164 -88 l-15 -23
-50 22 c-89 39 -199 27 -288 -32 -43 -28 -48 -29 -63 -15 -8 9 -19 16 -24 16
-5 0 -14 18 -20 40 -6 21 -17 43 -25 47 -7 4 -55 6 -105 2 -103 -6 -120 -16
-120 -76 0 -43 -18 -50 -67 -26 -28 13 -61 18 -123 18 -92 -1 -122 -11 -184
-65 l-33 -30 -25 38 c-39 59 -117 92 -216 92 -81 0 -137 -15 -162 -44 -18 -23
-27 -20 -45 13 -27 52 -71 76 -149 78 -38 1 -81 6 -95 12 -40 15 -128 31 -172
31 -30 0 -50 8 -79 31 -31 25 -48 31 -99 34 -34 1 -67 7 -74 13 -21 17 -140
46 -186 45 -38 -1 -48 4 -73 33 -47 53 -150 98 -224 97 -36 0 -73 -7 -93 -17
-18 -10 -34 -16 -35 -14 -1 2 -11 25 -21 52 -41 113 -175 187 -301 167z m145
-83 c51 -29 76 -54 96 -98 16 -35 16 -45 5 -86 -7 -25 -19 -48 -26 -51 -14 -5
-94 33 -94 46 0 5 7 25 16 46 19 46 11 82 -21 97 -42 19 -67 -10 -145 -164
-92 -181 -103 -213 -88 -241 25 -49 67 -50 102 -4 15 19 26 39 26 44 0 6 7 16
15 23 17 14 65 0 86 -26 18 -22 -8 -72 -54 -105 -50 -36 -111 -39 -176 -7 -99
48 -136 111 -111 191 23 72 141 296 171 327 45 44 127 48 198 8z m406 -189
c81 -38 124 -105 111 -172 -8 -43 -91 -253 -119 -302 -28 -49 -80 -85 -124
-85 -42 0 -133 31 -173 60 -17 12 -37 38 -46 58 -19 46 -8 95 53 241 52 125
85 185 115 206 33 22 128 20 183 -6z m-490 -39 c4 -6 38 -26 76 -45 58 -30 69
-39 69 -60 0 -40 -26 -41 -100 -5 -37 18 -75 30 -84 27 -21 -8 -21 13 1 58 17
36 27 43 38 25z m3474 -42 c13 -34 29 -96 62 -248 6 -25 14 -61 19 -80 23 -91
70 -302 70 -315 0 -24 -64 -41 -138 -36 -21 1 -23 4 -16 34 10 44 7 76 -17
176 -11 48 -24 106 -30 131 -29 126 -51 157 -103 144 -39 -9 -42 -52 -12 -180
14 -60 30 -131 37 -159 20 -91 33 -117 62 -130 l28 -11 -21 -27 c-41 -52 -138
-44 -159 14 -19 49 -28 82 -39 134 -6 28 -20 88 -31 135 -42 177 -40 209 12
240 37 23 67 25 114 8 29 -11 35 -10 48 8 9 12 13 30 10 49 -3 17 -8 46 -11
66 -8 45 5 54 99 68 4 0 11 -9 16 -21z m-2696 -42 c42 -14 78 -63 53 -72 -7
-3 -20 -22 -29 -42 -15 -37 -21 -56 -76 -257 -53 -189 -52 -187 -75 -180 -12
3 -36 10 -54 15 -19 4 -36 11 -39 14 -6 6 33 157 101 396 8 25 19 66 26 93 14
53 26 57 93 33z m200 -47 c52 -14 71 -46 63 -106 -4 -27 -11 -59 -16 -73 -4
-14 -30 -104 -56 -200 -26 -96 -51 -179 -56 -185 -9 -10 -96 9 -112 25 -6 6 1
44 16 97 14 48 36 126 48 173 12 47 26 96 30 110 18 54 20 95 5 123 -13 26
-13 28 8 37 29 11 26 11 70 -1z m284 -96 c16 -21 17 -45 2 -60 -11 -11 -23
-54 -39 -138 -47 -254 -62 -331 -69 -338 -9 -9 -100 10 -115 24 -5 4 -4 28 2
56 19 91 41 202 62 323 11 66 30 150 36 161 7 12 109 -11 121 -28z m210 -35
c23 -21 25 -28 19 -78 -3 -30 -10 -77 -16 -105 -21 -111 -31 -165 -48 -260
-16 -95 -18 -100 -44 -103 -42 -5 -93 12 -97 33 -2 11 2 42 7 69 12 54 42 217
62 328 10 58 10 70 -4 90 -9 12 -16 28 -16 34 0 32 101 26 137 -8z m1117 -6
c5 -19 20 -41 33 -49 30 -20 29 -30 -3 -56 -23 -17 -25 -25 -20 -61 3 -23 10
-104 16 -182 11 -157 20 -195 53 -209 12 -6 22 -17 22 -25 0 -12 -16 -16 -76
-18 -115 -4 -108 -21 -138 342 -9 101 -14 122 -33 143 -22 23 -22 24 -3 56 10
17 18 44 17 59 -3 31 11 37 78 35 41 -2 45 -4 54 -35z m436 -21 c19 -10 45
-35 57 -56 23 -41 41 -151 27 -165 -5 -5 -52 -14 -104 -20 -52 -7 -101 -17
-109 -23 -16 -14 -10 -150 9 -205 14 -37 49 -51 85 -32 27 14 36 49 28 108 -5
39 -4 42 23 52 15 6 41 10 58 8 25 -2 31 -9 39 -38 17 -66 -28 -145 -100 -175
-72 -30 -195 -14 -234 32 -30 34 -52 139 -65 318 -10 142 18 186 135 211 63
14 100 10 151 -15z m-1159 -20 c65 -41 83 -84 75 -179 -3 -42 -8 -77 -11 -80
-2 -2 -49 1 -103 8 -142 17 -144 15 -159 -142 -7 -79 9 -108 59 -108 36 0 68
48 68 103 0 45 13 52 75 43 41 -7 45 -10 45 -35 0 -58 -33 -120 -75 -143 -84
-46 -250 -6 -284 68 -15 34 -14 118 4 258 27 204 52 236 186 236 66 0 81 -4
120 -29z m451 -28 c36 -21 70 -81 72 -128 1 -30 1 -30 -54 -30 l-55 0 -3 53
c-4 61 -30 88 -76 76 -37 -9 -45 -50 -47 -261 -2 -180 -2 -183 21 -203 13 -11
31 -20 41 -20 29 0 59 48 59 95 0 53 14 65 68 58 40 -5 42 -7 42 -40 0 -49
-26 -99 -68 -131 -31 -24 -44 -27 -112 -26 -87 1 -139 24 -165 74 -19 37 -21
342 -3 402 12 41 58 87 100 100 40 13 145 2 180 -19z m96 -236 c7 -5 12 -22
10 -36 -3 -26 -4 -26 -83 -26 -44 0 -90 -4 -102 -8 -22 -8 -23 -6 -23 40 l0
48 92 -3 c51 -2 99 -9 106 -15z m296 -169 c6 -13 4 -18 -7 -18 -18 0 -25 44
-18 115 l5 50 6 -65 c4 -36 10 -73 14 -82z"/>
<path d="M3574 2942 c-6 -4 -18 -20 -27 -37 -24 -48 -125 -303 -132 -335 -10
-44 9 -70 50 -70 41 0 37 -7 122 200 66 158 80 223 53 240 -18 12 -51 13 -66
2z"/>
<path d="M6029 2603 c-13 -34 -2 -118 18 -138 17 -18 46 -19 74 -5 15 9 19 22
19 66 0 72 -20 104 -66 104 -28 0 -36 -5 -45 -27z"/>
<path d="M4885 2597 c-19 -30 -23 -120 -6 -141 16 -19 59 -20 83 -2 22 16 28
127 8 151 -19 23 -67 19 -85 -8z"/>
<path d="M7465 3166 c-90 -29 -166 -86 -190 -144 -12 -27 -18 -33 -32 -26 -10
4 -50 10 -88 12 -119 6 -224 -48 -275 -141 -23 -43 -25 -78 -8 -145 16 -68 35
-92 69 -92 33 0 33 -1 15 -54 -15 -44 -7 -90 34 -184 33 -77 68 -102 140 -102
58 0 130 28 130 51 0 5 12 9 28 9 15 0 60 12 100 27 84 30 111 58 92 93 -15
28 -6 36 25 22 30 -14 147 -16 173 -3 9 5 33 16 53 24 46 20 106 71 125 108
29 58 12 150 -45 233 -30 43 -53 57 -120 74 -47 11 -48 25 -6 45 42 20 45 47
13 112 -41 80 -134 112 -233 81z m141 -68 c20 -9 44 -47 44 -68 0 -10 -64 -40
-84 -40 -6 0 -19 14 -28 30 -12 22 -25 30 -44 30 -57 0 -80 -51 -49 -110 21
-40 63 -60 130 -60 69 0 143 -17 169 -39 26 -22 66 -110 66 -146 0 -78 -106
-155 -215 -155 -53 0 -60 3 -91 36 -45 48 -44 68 7 88 46 19 53 16 74 -24 30
-59 115 -43 115 21 0 33 -20 78 -44 98 -8 7 -62 18 -120 26 -146 19 -159 28
-196 124 -24 63 -17 99 27 137 73 63 172 85 239 52z m-374 -175 c26 -29 68
-113 68 -136 0 -7 4 -17 9 -23 15 -16 103 -282 99 -300 -4 -21 -81 -49 -106
-38 -9 4 -21 8 -27 8 -5 1 -10 17 -11 37 -1 19 -11 65 -23 102 -35 103 -65
123 -102 65 -38 -61 -5 -178 59 -208 38 -18 39 -24 7 -55 -30 -31 -104 -35
-130 -8 -25 25 -56 101 -62 153 l-5 45 81 80 c91 89 96 104 60 180 -27 57 -50
71 -87 53 -23 -11 -27 -20 -26 -53 0 -22 4 -52 8 -67 9 -30 -5 -42 -65 -53
-34 -6 -36 -5 -47 33 -17 56 -15 78 11 117 38 58 114 93 208 94 48 1 59 -3 81
-26z m207 -189 c39 -8 39 -18 0 -34 -32 -14 -48 -4 -49 28 0 14 5 14 49 6z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
